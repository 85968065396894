import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { SeminarCard } from "~/molecules/SeminarCard";
import { PATH } from "~/constant/path";
import { PageHeading } from "~/atoms/PageHeading";
import { Container } from "~/atoms/Container";
import { Pager } from "~/molecules/Pager";
import { colors } from "~/utils/colors";

interface Props {
  data: {
    allContentfulEvent: GatsbyTypes.ContentfulEventConnection;
  };
  pageContext: {
    limit: number;
    skip: number;
    totalPages: number;
    currentPage: number;
  };
}

const DESCRIPTION = [
  "具体的ですぐに役立つノウハウや、充実した質疑応答セッションなど、",
  "参加する価値を感じていただける高評価なセミナーを開催しています",
];

const IndexSeminar: React.FC<Props> = ({ data, pageContext }) => {
  const { allContentfulEvent } = data;
  const { currentPage, totalPages } = pageContext;

  return (
    <>
      <style jsx>{`
        .pageWrapper {
          background: ${colors.bgGrayGradient};
          padding-bottom: 50px;
        }

        .seminarList {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
      `}</style>
      <Layout
        title={PATH.EVENTS_SEMINAR.name}
        ogpDescription={DESCRIPTION.join("")}
        breadCrumbItems={[PATH.TOP, PATH.EVENTS, PATH.EVENTS_SEMINAR]}
      >
        <div className="pageWrapper">
          <PageHeading title={PATH.EVENTS_SEMINAR.name}>
            {DESCRIPTION.map((str, index) => (
              <Fragment key={`${str}_${index}`}>
                {DESCRIPTION.length - 1 === index ? (
                  str
                ) : (
                  <>
                    {str}
                    <br />
                  </>
                )}
              </Fragment>
            ))}
          </PageHeading>
          <Container>
            <ul className="seminarList">
              {allContentfulEvent.edges.map((edge) => {
                const { node } = edge;

                return (
                  <SeminarCard
                    key={node.contentful_id}
                    article={node}
                    href={`${PATH.EVENTS.to}/${node.eventCategory?.slug}/${node.contentful_id}`}
                  />
                );
              })}
            </ul>
          </Container>
          <Pager currentPage={currentPage} totalPages={totalPages} basePath={PATH.EVENTS_SEMINAR.to} />
        </div>
      </Layout>
    </>
  );
};

export default IndexSeminar;

export const indexSeminarQuery = graphql`
  query IndexSeminar($limit: Int!, $skip: Int!) {
    allContentfulEvent(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          contentful_id
          date
          endedAt
          eventCategory {
            slug
          }
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          location
          title
          receptionStatus
        }
      }
      totalCount
    }
  }
`;
