import { colors } from "~/utils/colors";

export interface Seminar {
  available: boolean;
  label: string;
  labelBgColor: string;
  textColor: string;
  bgColor: string;
  baseColor: string;
}

export const seminarStatus: { open: Seminar; close: Seminar; end: Seminar } = {
  open: {
    available: true,
    label: `受付中`,
    labelBgColor: `rgba(255, 100, 0, 0.8)`,
    textColor: colors.white,
    bgColor: colors.lightBlue,
    baseColor: colors.brand,
  },
  close: {
    available: false,
    label: `受付終了`,
    labelBgColor: `rgba(255, 100, 0, 0.8)`,
    textColor: colors.white,
    bgColor: colors.littleBrightGray,
    baseColor: colors.littleBrightGray,
  },
  end: {
    available: false,
    label: `開催終了`,
    labelBgColor: `rgba(50, 50, 50, 0.8)`,
    textColor: colors.defaultText,
    bgColor: colors.white,
    baseColor: colors.littleBrightGray,
  },
};
