import React from "react";
import { Link } from "gatsby";
import dayjs from "dayjs";
import { ReactComponent as LocationIcon } from "~/images/inline_svg/room.svg";
import { seminarStatus } from "~/constant/seminar";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import ThumbsBlankJpg from "~/images/thumbBlank.jpg";

interface Props {
  article: GatsbyTypes.ContentfulEvent;
  href: string;
}

export const SeminarCard: React.FC<Props> = ({ article, href }) => {
  const { date, ogpImage, title, endedAt, receptionStatus, location } = article;
  const seminar = dayjs().isAfter(endedAt)
    ? seminarStatus.end
    : (receptionStatus && seminarStatus.open) || seminarStatus.close;

  return (
    <>
      <style jsx>{`
        .card {
          background-color: ${colors.white};
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          margin: 15px;
          position: relative;
          transition: box-shadow 0.3s ease;
          width: calc(100% / 3 - 30px);
        }
        .card:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
        }
        .card:hover .cover {
          opacity: 0.8;
        }
        .cover {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(${ogpImage ? ogpImage.fluid?.src : ThumbsBlankJpg});
          border-radius: 6px 6px 0 0;
          padding-top: 64%;
          position: relative;
          transition-property: background-size, opacity;
          transition-duration: 0.4s;
          transition-timing-function: ease;
          width: 100%;
          height: 0;
        }
        .cover:after {
          ${!seminar.available && `content: "";`}
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(50, 50, 50, 0.3);
          border-radius: 6px 6px 0 0;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .dateContainer {
          background-color: ${seminar.labelBgColor};
          border-radius: 6px;
          position: absolute;
          top: 0;
          left: 0;
          height: 90px;
          width: 90px;
          z-index: 2;
        }
        .dateInner {
          align-items: center;
          display: flex;
          color: ${colors.white};
          flex-direction: column;
          justify-content: center;
          position: relative;
          height: 90px;
          width: 90px;
        }
        .seminarYear {
          font-size: 12px;
          line-height: 1;
          text-align: center;
        }
        .seminarMonthAndDate {
          display: flex;
          justify-content: center;
          margin-top: 5px;
          text-align: center;
          width: 100%;
        }
        .seminarMonth {
          font-size: 18px;
          line-height: 1;
        }
        .seminarSlash {
          position: relative;
          top: 2px;
          font-size: 22px;
          line-height: 1;
        }
        .seminarDate {
          font-size: 36px;
          font-weight: 500;
          line-height: 1;
          margin-left: 2px;
        }
        .seminarDay {
          font-size: 16px;
          line-height: 1;
        }
        .seminarStatusLabel {
          background-color: ${seminar.bgColor};
          color: ${seminar.textColor};
          display: inline-block;
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 12px;
          line-height: 1;
          padding: 5px 10px;
          border-radius: 50px;
          z-index: 2;
        }
        .seminarBody {
          color: ${colors.defaultText};
          padding: 20px 20px 10px;
        }
        .seminarTitle {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.015em;
        }
        .createdAt {
          color: ${colors.littleBrightGray};
          display: block;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.08rem;
        }
        .seminarLocation {
          display: flex;
          padding: 0 20px 20px;
        }
        .seminarLocationIcon {
          margin-right: 5px;
        }
        .seminarLocationIcon :global(svg) {
          display: block;
          fill: ${colors.brand};
          width: 20px;
        }
        .seminarLocationText {
          font-size: 13px;
          color: ${colors.littleBrightGray};
        }
        @media (${breakPoints.sp}) {
          .card {
            width: 100%;
            margin: 15px 0;
          }
        }
      `}</style>
      <li className="card">
        <Link to={href}>
          <div className="cover">
            <div className="dateContainer">
              <div className="dateInner">
                <div className="seminarYear">{dayjs(date).format("YYYY")}</div>
                <div className="seminarMonthAndDate">
                  <div className="seminarMonth">{dayjs(date).format("MM")}</div>
                  <div className="seminarSlash">&#047;</div>
                  <div className="seminarDate">{dayjs(date).format("DD")}</div>
                </div>
                <div className="seminarDay">{dayjs(date).format("ddd")}</div>
              </div>
            </div>
            <div className="seminarStatusLabel">{seminar.label}</div>
          </div>
          <div className="seminarBody">
            <h2 className="seminarTitle">{title}</h2>
          </div>
          {location && (
            <div className="seminarLocation">
              <div className="seminarLocationIcon">
                <LocationIcon />
              </div>
              <span className="seminarLocationText">{location}</span>
            </div>
          )}
        </Link>
      </li>
    </>
  );
};
